import { IframeMessageProxy } from 'iframe-message-proxy';
import IMPContainer from '../constants/iframe-message-proxy-container';

const getAuthorization = (application) => {
    const originalKey = atob(application.accessKey);
    const userAndKey = `${application.shortName}:${originalKey}`;
    const authorization = `Key ${btoa(userAndKey)}`;
    return authorization;
};

const getApplicationDataAsync = async (fullIdentity = null) => {
    const { response: application } = await IframeMessageProxy.sendMessage({
        action: IMPContainer.Actions.GET_APPLICATION,
        content: fullIdentity
    });
    application.authorization = getAuthorization(application);
    return application;
};

const getCurrentLanguageAsync = async () => {
    const { response } = await IframeMessageProxy.sendMessage({
        action: IMPContainer.Actions.GET_CURRENT_LANGUAGE
    });

    return response;
};

const getBucketDocuments = async () => {
    const { response } = await IframeMessageProxy.sendMessage({
        action: IMPContainer.Actions.SEND_COMMAND,
        content: {
            command: {
                method: IMPContainer.CommandMethods.GET,
                uri: "/buckets"
            },
            destination: "postmaster@msging.net"
        }
    });

    return response;
};

const getBucketDocumentContent = async (documentKey) => {
    const { response } = await IframeMessageProxy.sendMessage({
        action: IMPContainer.Actions.SEND_COMMAND,
        content: {
            command: {
                method: IMPContainer.CommandMethods.GET,
                uri: `/buckets/${documentKey}`
            },
            destination: "postmaster@msging.net"
        }
    });

    return response;
};

const deleteBucketDocument = async (documentKey) => {
    const { response } = await IframeMessageProxy.sendMessage({
        action: IMPContainer.Actions.SEND_COMMAND,
        content: {
            command: {
                method: IMPContainer.CommandMethods.DELETE,
                uri: `/buckets/${documentKey}`
            },
            destination: "postmaster@msging.net"
        }
    });

    return response;
};

const storeBucketJSON = async (documentKey, documentContent) => {
    const { response } = await IframeMessageProxy.sendMessage({
        action: IMPContainer.Actions.SEND_COMMAND,
        content: {
            command: {
                method: IMPContainer.CommandMethods.SET,
                uri: `/buckets/${documentKey}`,
                resource: documentContent,
                type: "application/json"
            }
        }
    });

    return response;
};

export { getApplicationDataAsync, getCurrentLanguageAsync, getBucketDocuments, getBucketDocumentContent, deleteBucketDocument, storeBucketJSON };
