import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { /* BdsInput, */ BdsSelect, BdsSelectOption } from 'blip-ds/dist/blip-ds-react';
import { useDispatch, useSelector } from 'react-redux';

import { setPeriod } from '../../store/period';

const SelectPeriod = ({ t }) => {

    const dispatch = useDispatch();

    const period = useSelector((state) => state.period.value);

    const handleperiod = useCallback((event) => {
        dispatch(setPeriod({ ...period, value: event.target.value }));
    }, [period]);

    // const handleNumberCustomPeriod = useCallback((event) => {
    //     dispatch(setPeriod({ ...period, customPeriod: { ...period.customPeriod, number: parseInt(event.target.value) } }));
    // }, [period]);

    // const handleUnitCustomPeriod = useCallback((event) => {
    //     dispatch(setPeriod({ ...period, customPeriod: { ...period.customPeriod, unit: event.target.value } }));
    // }, [period]);

    

    return (
        <div>
            <p style={{ marginBottom: "0%" }}>{t('paragraph.whatTimePeriod')}</p>
            <p className='warning'>{t('warnings.periodTimeFrequency')}</p>
            <div style={{ marginBottom: "10px" }}>
                <BdsSelect onBdsChange={handleperiod} icon='calendar' style={{ height: "56px !important" }}>
                    <BdsSelectOption value="today"><p className='select-options'>{t('options.today')}</p></BdsSelectOption>
                    <BdsSelectOption value="yesterday"><p className='select-options'>{t('options.yesterday')}</p></BdsSelectOption>
                    <BdsSelectOption value="thisWeek"><p className='select-options'>{t('options.thisWeek')}</p></BdsSelectOption>
                    <BdsSelectOption value="lastMonth"><p className='select-options'>{t('options.lastMonth')}</p></BdsSelectOption>
                    { /* <BdsSelectOption value="customPeriod"><p className='select-options'>{t('options.customPeriod')}</p></BdsSelectOption> */ }
                </BdsSelect>
            </div>
            {/* <div>
                {
                    period.value === 'customPeriod' &&
                    <div className='customPeriod'>
                        <p className='customPeriodLabel'>{t('last')}</p>
                        <BdsInput className='customPeriodNumber' type="number" min={1} placeholder={t('number')} onBdsChange={handleNumberCustomPeriod} />
                        <BdsSelect className='customPeriodUnit' onBdsChange={handleUnitCustomPeriod}>
                            <BdsSelectOption value='days'><p className='select-options'>{period.customPeriod.number > '1' ? t('days') : t('day')}</p></BdsSelectOption>
                            <BdsSelectOption value='weeks'><p className='select-options'>{period.customPeriod.number > '1' ? t('weeks') : t('week')}</p></BdsSelectOption>
                            <BdsSelectOption value='months'><p className='select-options'>{period.customPeriod.number > '1' ? t('months') : t('month')}</p></BdsSelectOption>
                            <BdsSelectOption value='years'><p className='select-options'>{period.customPeriod.number > '1' ? t('years') : t('year')}</p></BdsSelectOption>
                        </BdsSelect>
                    </div>
                }
            </div> */}
        </div>
    );
};

SelectPeriod.propTypes = {
    t: PropTypes.func
};

export default SelectPeriod;