import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import { getApplicationDataAsync } from '../../services/application-service';
import { showToast } from '../../services/common-service';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';

import Card from '../Card';
import FrequencyText from '../FrequencyText';

const ExistingSchedules = ({ t, existingSchedules }) => {
    const deleteSchedule = useCallback(async (schedule) => {
        const {  shortName, tenantId } = await getApplicationDataAsync();

        api.delete(`/schedules/${schedule.id}`, {
            headers: {
                'bot': shortName,
                'owner': tenantId,
                'period': schedule.period.value
            }
        }).catch(()=>{
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: t('warnings.errorDeletingSchedule')
            });
        });;

        window.location.reload();
    }, []);

    return (
        <Card>
            <ul className='grid'>
                {
                    existingSchedules.length > 0 &&
                    existingSchedules.map((schedule) => (
                        <li key={schedule.id}>
                            <div>
                                <Card className='item'>
                                    <div>
                                        <div className='deleteSchedule'>
                                            <bds-tooltip position="left-center" tooltip-text={t('button.removeSchedule')}>
                                                <bds-button-icon variant='secondary' size='short' icon='close' onClick={() => deleteSchedule(schedule)} />
                                            </bds-tooltip>
                                        </div>
                                        <FrequencyText t={t} period={schedule.period}/>
                                        <p className='scheduleData'><strong>{t('recipientEmail')}</strong> {schedule.email}</p>
                                        <p className='scheduleData'><strong>{t('dataToBeIncluded')}</strong> {schedule.dataArray.join(', ')}</p>
                                    </div>
                                </Card>
                            </div>
                        </li>
                    ))}
            </ul>
        </Card>
    );
};


ExistingSchedules.propTypes = {
    t: PropTypes.func,
    existingSchedules: PropTypes.array
};

export default ExistingSchedules;