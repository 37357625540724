import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import api from '../../services/api';
import { getApplicationDataAsync, getCurrentLanguageAsync } from '../../services/application-service';
import { showToast } from '../../services/common-service';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';

const SubmitButton = ({ t, setOpenAlert, setExistingSchedules, getExistingSchedules }) => {

    const period = useSelector((state) => state.period.value);

    const email = useSelector((state) => state.email.value);

    const dataArray = useSelector((state) => state.dataArray.value);

    const handleSubmit = useCallback(async () => {
        const { authorization, shortName, tenantId } = await getApplicationDataAsync();

        setOpenAlert(false);

        let schedules = await getExistingSchedules();

        const language = await getCurrentLanguageAsync();

        const newSchedule = {
            period,
            dataArray,
            email,
            language
        };
        
        api.post('/schedules', newSchedule, {
            headers: {
                'authorization': authorization,
                'bot': shortName,
                'owner': tenantId
            }
        }).then(() => {
            schedules = [...schedules, newSchedule];
            setExistingSchedules(schedules);

            showToast({
                type: BlipPortalToastTypes.SUCCESS,
                message: t('warnings.successfulScheduleCreation')
            });
        }).catch(()=>{
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: t('warnings.errorNewSchedule')
            });
        });


    }, [dataArray, period, email]);

    return <bds-button variant="primary" bold="true" onClick={handleSubmit}>{t('button.confirm')}</bds-button>;
};

SubmitButton.propTypes = {
    t: PropTypes.func,
    setOpenAlert: PropTypes.func,
    setExistingSchedules: PropTypes.func,
    getExistingSchedules: PropTypes.func
};

export default SubmitButton;