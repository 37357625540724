import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';

import Header from './components/Header';
import Card from '../../components/Card';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import SelectData from '../../components/SelectData';
import SelectPeriod from '../../components/SelectPeriod';
import EmailInput from '../../components/EmailInput';
import ExistingSchedules from '../../components/ExistingSchedules/ExistingSchedules';
import { getApplicationDataAsync } from '../../services/application-service';

const PAGE_ICON = 'chart-column';
const BLANK = '_blank';

const Home = () => {
    const { t } = useTranslation();

    const [openAlert, setOpenAlert] = useState(false);

    const getExistingSchedules = useCallback(async () => {
        let schedules;

        const { shortName, tenantId } = await getApplicationDataAsync();

        try {
            const response = await api.get('/schedules', {
                headers: {
                    'bot': shortName,
                    'owner': tenantId
                }
            });

    
            schedules = Object.values(response.data).map((schedule, index) => {
                const sla = schedule.map(innerStuff => (
                    {
                        id: innerStuff.id,
                        period: { 
                            value: Object.keys(response.data)[index],
                            customPeriod: Object.keys(response.data)[index] === 'customPeriod' ? { number: innerStuff.frequency[0], unit: innerStuff.frequency[1] } : null
                        },
                        dataArray: innerStuff.dataArray,
                        email: innerStuff.email
                    }
                ));

                return sla;
            }).flat();

            setExistingSchedules(schedules);

        } catch (e) {
            setExistingSchedules([]);
            schedules = [];
        }

        return schedules;
    }, []);

    const [existingSchedules, setExistingSchedules] = useState(getExistingSchedules);

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={t('title.homePage')}
                icon={PAGE_ICON}
                onClick={() => window.open("https://github.com/dawntech/blip-bucket-viewer", BLANK)}
            />
            <div className="flex flex-column justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <div className="home-select-container">
                    <div>
                        <bds-tabs>
                            <bds-tab group="tab1" label={t('title.newSchedule')} />
                            <bds-tab group="tab2" label={t('title.existingSchedules')} />
                        </bds-tabs>
                        <bds-tab-panel group="tab1">
                            <Card className="home-card">
                                <SelectPeriod t={t} />
                                <SelectData t={t} />
                                <EmailInput t={t} />
                                <div className='nowReportButton'>
                                    <Button type="submit" variant="primary" text={t('button.newReportScheduling')} onClick={() => setOpenAlert(true)} />
                                </div>
                            </Card>
                            <Alert
                                t={t}
                                openAlert={openAlert}
                                setOpenAlert={setOpenAlert}
                                setExistingSchedules={setExistingSchedules}
                                getExistingSchedules={getExistingSchedules}
                            />
                        </bds-tab-panel>
                        <bds-tab-panel group="tab2">
                            <ExistingSchedules t={t} existingSchedules={existingSchedules} />
                        </bds-tab-panel>
                    </div>
                </div>
            </div>
        </div>
    );
};

Home.propTypes = {};

export default Home;
