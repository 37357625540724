const translations = {
    title: {
        homePage: 'Analytics Reports',
        exemples: 'Examples',
        systemInfo: 'System info',
        userInfo: 'User info',
        existingSchedules: 'Existing Schedules',
        newSchedule: 'New schedule',
        newScheduleConfirm: 'The following schedule will be created:',
    },
    paragraph: {
        whichInformation: 'Which data do you want in the report?',
        whatTimePeriod: 'What time period will the report cover?',
        recipientEmail: 'To which email should the report be sent?'
    },
    options: {
        receivedMessages: "Received messages",
        sentMessages: "Sent messages",
        activeMessages: "Active messages",
        activeUsers: "Active users",
        engagedUsers: "Engaged users",
        openTickets: "Open tickets",
        closedTickets: "Closed tickets",
        today: "Today",
        yesterday: "Yesterday",
        thisWeek: "This week (starting on Monday)",
        lastMonth: "Last month",
        customPeriod: "Custom period"
    },
    warnings: {
        periodTimeFrequency: "This will also set the frequency of emails.",
        invalidEmail: "Please, enter a valid email.",
        invalidData: 'You have to choose at least one data to include in the report',
        invalidPeriod: 'Choose a valid period',
        confirmData: 'Confirm data submission',
        warning: 'Warning!',
        errorNewSchedule: 'Error when creating new schedule, please try again.',
        errorDeletingSchedule: 'Error when deleting schedule, please try again.',
        successfulScheduleCreation: 'New schedule created successfully'
    },
    link: {
        blipDataExample: 'Get blip data',
        swrExemple: 'SWR fetch data'
    },
    button: {
        repository: 'See the Documentation',
        newReportScheduling: 'Schedule report submission',
        cancel: 'Cancel',
        confirm: 'Confirm',
        removeSchedule: "Remove schedule"
    },
    menu: {
        goBack: 'Back'
    },
    name: 'Name',
    shortName: 'Short name',
    email: 'E-mail',
    phone: 'Phone',
    identity: 'Identity',
    identifier: 'Identifier',
    language: "en_US",
    accessKey: 'Access key',
    template: 'Template',
    creationDate: 'Creation date',
    last: 'Last',
    lastMasc: 'Last',
    lastFem: 'Last',
    lastPlural: 'Last',
    lastFemPlural: 'Last',
    number: 'number',
    days: 'days',
    day: 'day',
    weeks: 'weeks',
    week: 'week',
    months: 'months',
    month: 'month',
    years: 'years',
    year: 'year',
    todayDaily: "Today (daily)",
    yesterdayDaily: "Yesterday (daily)",
    thisWeek: "This week (weekly)",
    lastMonth: "Last month (monthly)",
    every: 'every',
    frequency: 'Period - Frequency:',
    recipientEmail: 'Recipient Email:',
    dataToBeIncluded: 'Data to be included:'
};

export default translations;
