import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
    value: '',
    customPeriod: {
        number: 1,
        unit: ''
    },
    stringValue: ''
};

export const periodSlice = createSlice({
    name: "period",
    initialState: { value: initialStateValue },
    reducers: {
        setPeriod: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setPeriod } = periodSlice.actions;

export default periodSlice.reducer;