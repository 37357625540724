import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { BdsInput } from 'blip-ds/dist/blip-ds-react';

import { setEmail } from '../../store/email';

const EmailInput = ({ t }) => {

    const dispatch = useDispatch();

    const handleEmail = (event) => {
        dispatch(setEmail(event.target.value));
    };

    return (
        <div>
            <p style={{ marginBottom: "2px" }}>{t('paragraph.recipientEmail')}</p>
            <BdsInput
                icon="email"
                type="email"
                onBdsChange={handleEmail}
                className="home-select"
            />
        </div>
    );
};

EmailInput.propTypes = {
    t: PropTypes.func
};

export default EmailInput;