import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { BdsSelectChips, BdsSelectOption } from 'blip-ds/dist/blip-ds-react';

import { setDataArray } from '../../store/dataArray';

const SelectData = ({ t }) => {
    const dispatch = useDispatch();

    const dataArray = useSelector((state) => state.dataArray.value);

    const handleDataArray = useCallback((event) => {
        dispatch(setDataArray(event.detail.data));
    }, [dataArray]);

    return (
        <div>
            <p style={{ marginBottom: "2px", marginTop: "0px" }}>{t('paragraph.whichInformation')}</p>
            <BdsSelectChips can-add-new={false} className="home-select" icon='chart-line' onBdsChangeChips={handleDataArray}>
                <BdsSelectOption value="1"><p className='select-options'>{t('options.receivedMessages')}</p></BdsSelectOption>
                <BdsSelectOption value="2"><p className='select-options'>{t('options.sentMessages')}</p></BdsSelectOption>
                <BdsSelectOption value="3"><p className='select-options'>{t('options.activeMessages')}</p></BdsSelectOption>
                <BdsSelectOption value="4"><p className='select-options'>{t('options.activeUsers')}</p></BdsSelectOption>
                <BdsSelectOption value="5"><p className='select-options'>{t('options.engagedUsers')}</p></BdsSelectOption>
            </BdsSelectChips>
        </div>
    );
};

SelectData.propTypes = {
    t: PropTypes.func
};

export default SelectData;