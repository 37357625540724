const translations = {
    title: {
        homePage: 'Analytics Reports',
        exemples: 'Exemplos',
        systemInfo: 'Informações do sistema',
        userInfo: 'Informações do usuário',
        existingSchedules: 'Agendamentos existentes',
        newSchedule: 'Novo agendamento',
        newScheduleConfirm: 'O seguinte agendamento será criado:',
    },
    paragraph: {
        whichInformation: 'Você quer quais dados no relatório?',
        whatTimePeriod: 'Qual período de tempo o relatório vai abranger?',
        recipientEmail: 'Para qual e-mail o relatório deve ser enviado?'
    },
    options: {
        receivedMessages: "Mensagens recebidas",
        sentMessages: "Mensagens enviadas",
        activeMessages: "Mensagens ativas",
        activeUsers: "Usuários ativos",
        engagedUsers: "Usuários engajados",
        openTickets: "Tickets abertos",
        closedTickets: "Tickets fechados",
        today: "Hoje",
        yesterday: "Ontem",
        thisWeek: "Essa semana (começando na Segunda-feira)",
        lastMonth: "Último mês",
        customPeriod: "Período customizado"
    },
    warnings: {
        periodTimeFrequency: "Isso também vai definir a frequência dos emails.",
        invalidEmail: "Por favor, insira um email válido.",
        invalidData: 'Você precisa escolher ao menos 1 dado para incluir no relatório',
        invalidPeriod: 'Escolha um período válido',
        confirmData: 'Confirme o envio dos dados',
        warning: 'Aviso!',
        errorNewSchedule: 'Erro ao criar novo agendamento, por favor tente novamente.',
        errorDeletingSchedule: 'Erro ao deletar agendamento, por favor tente novamente.',
        successfulScheduleCreation: 'Novo agendamento criado com sucesso'
    },
    link: {
        blipDataExample: 'Obtenha dados do blip',
        swrExemple: 'Buscar dados com SWR'
    },
    button: {
        repository: 'Veja a Documentação',
        newReportScheduling: 'Agendar envio do relatório',
        cancel: 'Cancela',
        confirm: 'Confirma',
        removeSchedule: "Remover agendamento"
    },
    menu: {
        goBack: 'Voltar'
    },
    name: 'Nome',
    shortName: 'Nome abreviado',
    email: 'E-mail',
    phone: 'Telefone',
    identity: 'Identificador',
    identifier: 'Identificador',
    language: 'pt_BR',
    accessKey: 'Chave de acesso',
    template: 'Template',
    creationDate: 'Data de criação',
    last: 'Último(s)',
    lastMasc: 'Último',
    lastFem: 'Última',
    lastPlural: 'Últimos',
    lastFemPlural: 'Últimas',
    number: 'número',
    days: 'dias',
    day: 'dia',
    weeks: 'semanas',
    week: 'semana',
    months: 'meses',
    month: 'mês',
    years: 'anos',
    year: 'ano',
    todayDaily: "Hoje (diariamente)",
    yesterdayDaily: "Ontem (diariamente)",
    thisWeek: "Essa semana (semanalmente)",
    lastMonth: "Último mês (mensalmente)",
    every: 'a cada',
    frequency: 'Período - Frequência:',
    recipientEmail: 'Email destinatário:',
    dataToBeIncluded: 'Dados a serem incluídos:'
};

export default translations;
