import { configureStore } from '@reduxjs/toolkit';
import periodReducer from './period';
import emailReducer from './email';
import dataArrayReducer from './dataArray';
import existingSchedulesReducer from './existingSchedules';

export default configureStore({
    reducer: {
        period: periodReducer,
        email: emailReducer,
        dataArray: dataArrayReducer,
        existingSchedules: existingSchedulesReducer
    }
});